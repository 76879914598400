<template> <div></div></template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ClearCache",
  mounted() {
    this.clearCache();
    this.$router.push("/");
  },
  methods: {
    ...mapActions({
      clearCache: "STATE_CLEAR"
    })
  }
};
</script>

<style scoped></style>
